import axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "@/utils/toast";
import { locale } from "../i18n";
import _ from 'lodash';
export const arr = num => {
    let arr = []
    for (let i = 0; i < num; i++) {
        arr.push(i)
    }
    return arr
}

export const img = url => {
    return require(`@/assets/img${url}`)
}

export const formatNum = val => {
    const num = Number(val)
    if (num || num === 0) {
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        return val
    }
}

const { agent, key, autoSpin, customerUrl, jackpotType, singleGame, eAgent, eKey, confServer } = window.config
let agentVal = agent
let keyVal = key

const post = (url, data = {}, agent, key) => {
    const params = {
        timestamp: new Date().getTime(),
        ...data
    }
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(params), CryptoJS.MD5(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).ciphertext.toString()
    return axios.post(`${url}?agent=${agent}`, encrypted, {
        headers: {
            'Content-Type': 'text/plain',
        }
    })
}

function objectToQueryString(obj) {
    const queryString = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            queryString.push(`${encodedKey}=${encodedValue}`);
        }
    }
    return queryString.join('&');
}

export const getLocationParams = key => {
    const search = window.location.search.slice(1)
    const params = {}
    search.split('&').forEach(el => {
        const arr = el.split('=')
        params[arr[0]] = arr[1]
    })
    return key ? params[key] : params
}

export const getJackpot = () => post('/api/v1/febGetJackpots', {}, agent, key)

let requesting = false

const loginCallBack = (url) => {
    window.open(url, '_blank')
}
//跳转大厅
const fetchLobbyUrl = (params, gameData, callback = loginCallBack) => {
    // 使用 URLSearchParams 提取参数
    // I0001-大厅，I0002-游戏
    // const urlParams = new URLSearchParams(params);
    // const singleGame = urlParams.get('singleGame');
    // const newParam = singleGame == 2 ? "&p=I0001" : "&p=I0002";


    return post('/api/v2/gameEntrance', params, agentVal, keyVal).then(res => {
        const { code, data, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                setTimeout(() => {
                    const urlArr = data.gameUrl.split('/')
                    urlArr[0] = window.location.protocol
                    urlArr[2] = window.location.host + '/playGame'
                    const url = urlArr.join('/')

                    //跳转对应奖池游戏
                    if (gameData.gameName || gameData.jackpotName) {
                        const gameUrl = `${url}&gameName=${gameData.gameName}&jackpotName=${gameData.jackpotName}`;
                        callback(gameUrl)
                    } else {
                        // //跳转默认
                        callback(url)
                    }
                    requesting = false
                }, 300);
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = false
    })
}

//跳转游戏
const getGameUrl = (params, type) => {
    // 使用 URLSearchParams 提取参数
    // I0001-大厅，I0002-游戏
    // const urlParams = new URLSearchParams(params);
    // const singleGame = urlParams.get('singleGame');
    // const newParam = singleGame == 2 ? "&p=I0001" : "&p=I0002";

    return new Promise((resolve, reject) => {
        axios.get(`/api/v1/gameEntrance?${params}`).then(res => {
            const { code, data, message } = res.data
            if (code === '200') {
                // setTimeout(() => {
                //     const urlArr = data.gameUrl.split('/')
                //     urlArr[0] = window.location.protocol
                //     urlArr[2] = window.location.host + (type === '0' ? '/playGame' : '')
                //     const url = urlArr.join('/')
                //     window.open(url, '_blank')
                //     // window.open(data.gameUrl, '_blank');
                //     requesting  = false
                // }, 300);
                const urlArr = data.gameUrl.split('/')
                urlArr[0] = window.location.protocol
                urlArr[2] = window.location.host + (type === '0' ? '/playGame' : '')
                const url = urlArr.join('/')
                requesting = false
                resolve(url)
            } else {
                toast(message)
                requesting = false
                reject()
            }
        })
    })
}

export const sendEmail = (params, callback) => {
    requesting = true

    return post('/api/v1/sendEmail', params, agent, key).then(res => {
        const { code, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                callback && callback()
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = true
    })
}

let currentCurrency = 'USD'
export const login = (gameData = {}, callback) => {
    //币种有变化，重新获取汇率，重新登录
    // if (!requesting || (currentCurrency !== window.globalCurrency)) {
        // requesting = true

        let agentVal, keyVal;
        let Ewallet = getLocationParams('Ewallet');

        agentVal = Ewallet ? eAgent : agent
        keyVal = Ewallet ? eKey : key

        const _currency = window.globalCurrency || 'USD';
        currentCurrency = _currency;
        const _rate = window.globalExchangeRate || 1;

        const randomNumber = Math.floor(Math.random() * 1000) + 1;
        const userName = 'ISlot_' + randomNumber.toString().padStart(3, '0')
        const params = { currency: 'USD', userName, password: '******', aliasName: userName }
        const gameParams = { lang: localStorage.getItem('Language') || locale, singleGame: '0', type: '0' }

        post('/api/v1/userLogin', params, agentVal, keyVal).then(res => {
            const { code, data, message } = res.data
            if (code === '200') {
                let _data = {
                    agent: agentVal,
                    ticket: data.token,
                    currency: _currency,
                    callbackUrl: window.location.href,
                    jackpotType: jackpotType,
                    autoSpin,
                    customerUrl,
                    rate: _rate,
                    // singleGame: (gameParams.slotId && singleGame === '0') ? '1' : singleGame,
                    singleGame: gameParams.slotId ? '1' : (gameParams.type === '1' ? '0' : singleGame),
                    ...gameParams
                }

                if (Ewallet)
                    _data.userToken = data.token;
                else
                    _data.ticket = data.token;

                //进厅改为post请求
                if (_data.singleGame !== '1') {
                    fetchLobbyUrl(_data, gameData, callback)
                } else {
                    getGameUrl(objectToQueryString(_data), gameParams.type).then(res => callback && callback(res)).catch(() => { })
                }
            } else {
                toast(message)
                requesting = false
            }
        }).catch(() => {
        })
    // }
}

//获取所有配置数据
export const getConfigurationData = (params, callback) => {
    const lang = localStorage.getItem('Language') || locale
    params = `id=${lang.toUpperCase()}Version&version=v1.0.16`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res.data.data.info);
        })
    })
}

//获取游戏配置数据
export const getGameData = (params, callback) => {
    const lang = localStorage.getItem('Language') || locale
    params = `id=Game${lang.toUpperCase()}Version&version=v1.0.5`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res?.data?.data?.info);
        })
    })
}

//获取游戏配置数据
export const getCurrencyListData = (params, callback) => {
    params = `id=getCurrencyList&version=v1.0.2`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res?.data?.data?.info);
        })
    })
}

//获取所有tdk配置数据
export const getTdkConfigData = (callback) => {
    let params = `id=iotdk&version=v1.0.1`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res.data.data.info);
        })
    })
}

export const getTdkData = (route, callback) => {
    let tdkData = null;
    let router = route
    getTdkConfigData(val => {
        const lang = localStorage.getItem('Language') || locale
        tdkData = val.TDK[lang][router];
        callback(tdkData)
    });
}

export const setTdk = (tdkdata, callback) => {
    let tdk = tdkdata || {
        title: "iSLOT - Live Slot , Winning On-the-Roads",
        description: "The World’s Largest Online Live Slot Machine Platform, Offering Authentic and Compliant Remote-Controlled Slot Machines with Synchronized Video. With Prize Pools Exceeding Millions of Dollars and Hundreds of the Most Popular Land-Based Slot Machines Available on the iSLOT Platform",
        keywords: "Slot Machine, Live Slot Machine, Slot, Live Dealer, Big Wins,  Road Map, Free Trial"
    };

    document.title = tdk.title;

    document.querySelector("meta[name='title']")?.setAttribute('content', tdk?.title);
    document.querySelector("meta[property='og:title']")?.setAttribute('content', tdk?.title);
    document.querySelector("meta[name='al-title']")?.setAttribute('content', tdk?.title);

    document.querySelector("meta[name='description']")?.setAttribute('content', tdk?.description);
    document.querySelector("meta[property='og:description']")?.setAttribute('content', tdk?.description);

    document.querySelector("meta[name='keywords']")?.setAttribute('content', tdk?.keywords);
    document.querySelector("meta[property='og:keywords']")?.setAttribute('content', tdk?.keywords);
}

export const getJackpotList = (callback) => {
    requesting = true

    return post('/api/v1/getJackpots', { productId: 'IS1' }, agent, key).then(res => {
        const { code, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                callback && callback(res.data)
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = true
    })
}

export const isExternalLink = (url) => {
    const protocols = ['http://', 'https://', 'ftp://'];
    return protocols.some(protocol => url.startsWith(protocol));
}

export const scrollToView = (className) => {
    const dom = document.getElementsByClassName(className)[0]
    if (!dom) return
    const top = dom?.offsetTop - 40
    document.getElementById('root').scrollTo({ top, behavior: 'smooth' })
}

export const paginateArray = (arr, page, pageSize) => {
    // 确保数组存在，并且 pageSize 是正数
    if (!Array.isArray(arr) || pageSize <= 0) return [];
    // 计算从哪里开始截取
    const start = 0;
    const end = pageSize * (page + 1); // 计算取出元素的终点
    // 使用 slice 截取数组，确保不会超出数组范围
    return arr.slice(start, Math.min(end, arr.length));
}

//由于当前是JsonEditor没有后端处理数据，这里统一处理游戏相关数据，避免后台繁杂的重复配置
//通过游戏id反查游戏列表，拿到完整的游戏数据。这样处理的好处是将来换新的后端数据源了不用修改各组件本身,去掉或修改适配器即可
export const gameDataAdapter = (val) => {
    if (!val) return
    const GameList = val.GameList
    let { HomeGame, OtherGame, FilterGame } = val
    HomeGame.games = filterGames(HomeGame?.games, GameList)
    OtherGame.games = filterGames(OtherGame?.games, GameList)
    for (let index = 0; index < FilterGame?.gameList?.length; index++) {
        FilterGame.gameList[index].games = filterGames(FilterGame?.gameList[index]?.games, GameList)
    }
    const gameData = {
        HomeGame,
        OtherGame,
        FilterGame,
    }
    return gameData
}

const filterGames = (games, GameList) => {
    if (!games || !GameList) return
    return games.map(aItem => GameList.find(bItem => bItem.gameId === aItem.gameId)).filter(Boolean);
}

// 获取汇率接口
export const getCurrencyApi = (param, callback) => {
    requesting = true;
    return new Promise((resolve, reject) => {
        axios.get(`https://api.exchangerate-api.com/v4/latest/USD`)
            .then(res => {
                callback(res.data.rates, true); // 成功时调用回调
                resolve(res.data.rates); // 解析成功的 Promise
            })
            .catch(error => {
                // console.error('汇率接口请求失败:', error); // 输出错误信息
                // reject(error); // 拒绝 Promise，并传递错误信息
                callback(null, false); // 处理失败时，回调传入 null 或其他标识
            });
    });
}


