import { useEffect, useState, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { getConfigurationData, getGameData, gameDataAdapter } from "@/utils/func";
import { useSetRecoilState } from "recoil";
import { dataState, gameDataState, screenState, gameUrlState, langState, pageTypeState } from '../store';
import { locale } from "../i18n";
import { changeLanguage } from "i18next";

function App() {
    const [isH5, setIsH5] = useState(null)
    const setData = useSetRecoilState(dataState);
    const setGameDataState = useSetRecoilState(gameDataState);
    const setScreen = useSetRecoilState(screenState);
    const setLang = useSetRecoilState(langState)
    const setPageType = useSetRecoilState(pageTypeState)

    useEffect(() => {
        // 获取浏览器url 上 pageType 的值
        const pageType = new URLSearchParams(window.location.search).get('pageType')
        console.log('pageType', pageType)
        const isIframe = pageType ? pageType === 'iframe' || window.sessionStorage.getItem('isIframe') === 'true' : false

        if (isIframe) {
            window.sessionStorage.setItem('isIframe', 'true')
        }
        const lnguageType = new URLSearchParams(window.location.search)?.get('lnguageType')
        if (lnguageType && lnguageType == 'zh-CN') {
            setLang('zh')
            changeLanguage('zh')
            localStorage.setItem('Language', 'zh')
        } else if (lnguageType && lnguageType != 'zh-CN') {
            setLang('en')
            changeLanguage('en')
            localStorage.setItem('Language', 'en')
        } else {
            setLang('zh')
            changeLanguage('zh')
            localStorage.setItem('Language', 'zh')
        }
        setPageType(isIframe)
    }, [])


    useEffect(() => {
        setLang(locale || 'en')
        getConfigurationData(locale, val => {
            setData(val)
        });
        getGameData(locale, val => {
            const newData = gameDataAdapter(val)
            setGameDataState(newData)
        });
    }, [setLang, setData, setGameDataState])

    useEffect(() => {
        const setRootFontSize = () => {
            let width = window.innerWidth
            const max = 1280
            const min = 366
            width = width > max ? max : width < min ? min : width;
            let size = 100 * width / 375
            const root = document.documentElement
            root.style.fontSize = size + 'px';
        }
        const onResize = () => {
            const IsH5 = window.innerWidth < 1280
            if (IsH5 !== isH5) {
                setIsH5(IsH5)
                setScreen(IsH5)
                localStorage.setItem('isH5', String(IsH5))
                document.getElementsByTagName('html')[0].setAttribute('class', IsH5 ? 'H5' : 'PC')
            }
            if (IsH5) {
                setRootFontSize();
            } else {
                document.documentElement.style.fontSize = ''
            }
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [isH5, setScreen])

    return (
        <Suspense>
            {
                isH5 !== null && <Outlet />
            }
        </Suspense>
    );
}

export default App;
